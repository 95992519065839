import { Navigate } from 'react-router-dom';
import UseAuth from '../../component/UseAuth';

const PrivateRoute = ({ element }) => {
  const isAuthenticated = UseAuth();

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
