import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MdOutlineVisibility } from "react-icons/md";
import { MdOutlineVisibilityOff } from "react-icons/md";
import Error from "../../component/notification/error";
import Header from "../../component/common/header";
import { Container } from "@mui/material";

const SignUp = (props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [validPassword, setValidPassword] = useState(true);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const SetNotification = props.SetNotification;

  useEffect(() => {
    if (error) {
      resetMessage();
    }
  }, []);

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setValidPassword(validatePassword(newPassword));
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const validatePassword = (password) => {
    const pattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    return pattern.test(password);
  };
  const validateForm = () => {
    if (!email || !password) {
      setError('必須項目が入力されていません。');
      return false;
    }

    if (!email.includes("@")) {
      setError("メールアドレスには@が必要です。");
      return false;
    }
    const parts = email.split("@");
    if (parts.length !== 2 || !parts[1]) {
      setError("有効なドメイン部分を含むメールアドレスを入力してください。");
      return false;
    }
    if (!parts[1].includes(".")) {
      setError("ドメイン部分には少なくとも1つのドットが必要です。");
      return false;
    }
    // ドメインの最後が「com」または「jp」で終わっているか確認
    const domainParts = parts[1].split(".");
    const topLevelDomain = domainParts[domainParts.length - 1]; // 最後の部分を取得
    if (topLevelDomain !== "com" && topLevelDomain !== "jp") {
      setError("メールアドレスのドメインは「com」または「jp」で終わる必要があります。");
      return false;
    }

    if (!validPassword) {
      setError("パスワードは8文字以上で、少なくとも小文字1文字、大文字1文字、数字1文字、記号1文字を含む必要があります。");
      return false;
    }
    return true;
  };

  const resetMessage = () => {
    setError('');
    console.log('>>> ', error);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const signup_data = {
        email: email,
        password: password,
      };
      axios
        .post(`${apiUrl}/api/authentication/register/`, signup_data)
        .then((response) => {
          console.log("Server response:", response.data["success"]);
          navigate("/login");
          SetNotification(response.data["success"]);
        })
        .catch((error) => {
          console.log(error.response)
          setError(error.response.data.error);
        });
    }
  };

  return (
    <div className="h-screen overflow-hidden">
      <Header />
      <Container className="h-full flex flex-col justify-center">
        <div className="h-full mx-16 pb-20">
          <div className="h-full flex flex-col items-center justify-center flex-grow mx-24 px-24">
            <div className="w-full max-w-md bg-white">
              <div className="w-full" noValidate id="sign_in_form">
                <div className="mb-10 text-center">
                  <h2 className="text-gray-800 text-2xl font-bold text-center">アカウントの作成</h2>
                </div>
                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700">
                    メールアドレス <span className="text-red-500">必須</span>
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    className="w-full px-3 py-3 mt-1 border-2 border-gray-500 rounded-lg focus:outline-none  focus:border-blue-300"
                    autoComplete="off"
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700">
                    パスワード <span className="text-red-500">必須</span>
                  </label>
                  <div style={{ width: '100%', position: 'relative' }}>
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      required
                      value={password}
                      onChange={handlePasswordChange}
                      error={!validPassword && password.length > 0}
                      helperText={
                        !validPassword && password.length > 0
                          ? "パスワードは8文字以上で、少なくとも小文字1文字、大文字1文字、数字1文字、記号1文字を含む必要があります。※次の記号は使用できません。（&quot;&#39;=~|&lt;&gt;?）"
                          : ""
                      }
                      className="w-full px-3 py-3 mt-1 border-2 border-gray-500 rounded-lg focus:outline-none  focus:border-blue-300"
                    />
                    <button
                      type="button"
                      onClick={handleTogglePassword}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      {showPassword ? <MdOutlineVisibility /> : <MdOutlineVisibilityOff />}
                    </button>
                  </div>
                  {
                    !validPassword && password.length > 0
                      ? <div className="text-sm text-red-600 font-semibold">パスワードは8文字以上で、少なくとも小文字1文字、大文字1文字、数字1文字、記号1文字を含む必要があります。<br></br>※次の記号は使用できません。（&quot;&#39;=~|&lt;&gt;?）</div>
                      : <div className="text-sm text-gray-700">パスワードには半角英数字に記号を組み合わせた8文字以上を入力してください。※次の記号は使用できません。（&quot;&#39;=~|&lt;&gt;?）</div>
                  }
                </div>
                <button
                  type="submit"
                  className="w-full py-3 text-white bg-[#2467C1] rounded-full hover:bg-blue-800"
                  onClick={handleSubmit}
                >
                  アカウントを作成する
                </button>
                <div className="flex justify-center mt-6 space-x-4 text-sm font-semibold text-gray-700">
                  <div
                    onClick={() => navigate("/login")}
                    className="text-sm text-blue-500 underline"
                  >
                    ログインはこちら
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Error content={error} />
      </Container>
    </div>
  );
};

export default SignUp;
