import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './page/auth/Login';
import SecurityCode from "./page/auth/SecurityCode";
import Register from './page/auth/Register';
import ForgotPassword from "./page/auth/ForgotPassword";
import WaitEmail from "./page/auth/WaitEmail";
import ResetPassword from "./page/auth/ResetPassword";
import DashboardRouter from "./page/dashboard/DashboardRouter";
import { UserProvider } from "./context/UserContext";
import initializeApp from "./init";

import { GoogleOAuthProvider } from '@react-oauth/google'
import useBrowserBack from "./utils/useBrowserBack";
import MailVerify from "./page/auth/MailVerify";
import PrivateRoute from "./page/auth/PrivateRoute";

initializeApp();

const App = () => {
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const [notification, SetNotification] = useState("");
  const [email, setEmail] = useState("");

  // ブラウザバックを監視
  // Router内でフックを使うためのコンポーネント
  const BrowserNavigationHandler = () => {
    useBrowserBack();
    return null;
  };

  useEffect(() => {
    console.log("App component mounted");
  }, []);

  return (
    <GoogleOAuthProvider clientId={clientId || ''}>
      <Router>
        <BrowserNavigationHandler />
        <UserProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login content={notification} setEmail={setEmail}  SetNotification={SetNotification} />} />
            <Route path="/verify-code" element={<SecurityCode email={email} SetNotification={SetNotification} />} />
            <Route path="/register" element={<Register SetNotification={SetNotification} />} />
            <Route path="/mail-verify" element={<MailVerify />} />
            <Route path="/forget-password" element={<ForgotPassword />} />
            <Route path="/wait-email" element={<WaitEmail />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/dashboard/*" element={<PrivateRoute element={<DashboardRouter />} />} />
          </Routes>
        </UserProvider>
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;

