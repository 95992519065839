import React, { useContext, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import CustomDatePicker from './CustomDatePicker';
import TypeCall from './TypeCall';
import SelectUser from './SelectUser';
import { UserContext } from '../../context/UserContext';

const SearchInput = ({selected, startDate, endDate, typeCall, onChange}) => {
  const { user } = useContext(UserContext);

  // ユーザーIDの変更をハンドリング
  const handleUserChange = (newUserId) => {
    onChange({
      userId: newUserId,
      startDate: startDate,
      endDate: endDate,
      typeCall: typeCall
    });
  };

  // 日付の変更をハンドリング
  const handleDateChange = (newStartDate, newEndDate) => {
    onChange({
      userId: selected, // selectedは親から渡されているuserId
      startDate: newStartDate,
      endDate: newEndDate,
      typeCall: typeCall
    });
  };

  // 通話種別をハンドリング
  const handleTypeCallChange = (newTypeCall) => {
    onChange({
      userId: selected,
      startDate: startDate,
      endDate: endDate,
      typeCall: newTypeCall
    })
  }

  return (
    <div>
      <div className="flex items-center mb-6">
        {user?.is_admin ? (
          <SelectUser selected={selected} onChange={handleUserChange} user='0' />
          ):null}
        <TypeCall selected={typeCall} onChange={handleTypeCallChange} />
        <div className="flex p-4 space-x-2 items-center">
          <div>
            <CustomDatePicker
              label={"日付 (FROM)"}
              selected={startDate}
              onChange={(date) => handleDateChange(date, endDate)}
              className="pt-2"
            />
          </div>
          <div>
            <CustomDatePicker
              label={"日付 (TO)"}
              selected={endDate}
              onChange={(date) => handleDateChange(startDate, date)}
              className="pt-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
