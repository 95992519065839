import React, { useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import axios from 'axios';

const SelectUser = ({ onChange, user, page }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('0');
  const [error, setError] = useState(null); // エラーハンドリング用

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`${apiUrl}/api/users/`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      setUsers(response.data);
    })
    .catch((error) => {
      console.log("Error: ", error);
      localStorage.removeItem('token');
      setError("ユーザー情報の取得に失敗しました。")
    })
  }, [apiUrl]);

  const handleChange = (event) => {
    const userid = event.target.value;
    setSelectedUserId(userid);
    onChange(userid); // 親コンポーネントに通知
  };

  return (
    <div className=' flex flex-col items-start p-4 pl-0'>
      <label htmlFor="callType" className="font-semibold mb-2">利用者</label>
      <FormControl sx={{width: page === "callResult" ? 240 : 300}}>
        <Select
          value={selectedUserId}
          onChange={handleChange}
          style={{ height: 57 }}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            const selectedUser = users.find(user => user.user_id === selected);
            return (
              <Typography className={`${user==='1'? (selectedUser ? '' : 'text-[#909090]') : ''}`}>
                {selectedUser ? selectedUser.user_name
                  : (page==='CallSetting' ? '利用者を選択してください' : 'すべて')
                }
              </Typography>
            );
          }}
        >
          { user==='0' ? <MenuItem value='0'>すべて</MenuItem> : null }
          {users.map((data) => (
            data.is_valid ?
            <MenuItem
              key={data.user_id}
              value={data.user_id}
            >
              {data.user_name}
            </MenuItem> : ''
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectUser;
