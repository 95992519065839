import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Error from "../notification/error";
import Notification from "../notification/notification";

const SlotCard = ({ buttonTextBlue, buttonTextRed, userId }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fileInputRef = useRef(null);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');

  // 各項目のステートとエラーメッセージ用のステートを追加
  const [variables, setVariables] = useState({
    var1: '',
    var2: '',
    var3: '',
    var4: '',
    var5: '',
    email: '',
    subject: '',
  });
  const [errors, setErrors] = useState({
    var1: '',
    var2: '',
    var3: '',
    var4: '',
    var5: '',
    email: '',
    subject: '',
  });

  useEffect(() => {
    resetMessage();

    if(userId==='1' || userId==='0') return;

    const token = localStorage.getItem('token');
    const params = {};

    params['user_id'] = userId;

    axios.get(`${apiUrl}/api/phone_number/register/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: params
    })
    .then(response => {
      const phone_number = response.data.phone_number;
      setPhoneNumber(phone_number);
    })
    .catch((error) => {
      setError(
        error.response?.data?.error || '電話番号が取得できませんでした。'
      );
    });

    axios.get(`${apiUrl}/api/receiving_list/inbound/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: params
    })
    .then(response => {
      const data = {
        var1: response.data.receiving_list_variable_1,
        var2: response.data.receiving_list_variable_2,
        var3: response.data.receiving_list_variable_3,
        var4: response.data.receiving_list_variable_4,
        var5: response.data.receiving_list_variable_5,
        email: response.data.email,
        subject: response.data.subject,
      };
      setVariables(data);
    })
    .catch((error) => {
      const data = {
        var1: '',
        var2: '',
        var3: '',
        var4: '',
        var5: '',
        email: '',
        subject: '',
      };
      setVariables(data);
      setError(
        error.response?.data?.error || '受電リストデータが取得できませんでした。'
      );
    });
  }, [apiUrl, userId]);

  const resetMessage = () => {
    setError('');
    setMessage('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVariables({ ...variables, [name]: value });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!variables.var1) newErrors.var1 = "必須項目です";
    if (!variables.var2) newErrors.var2 = "必須項目です";
    if (!variables.var3) newErrors.var3 = "必須項目です";
    if (!variables.var4) newErrors.var4 = "必須項目です";
    if (!variables.var5) newErrors.var5 = "必須項目です";
    if (!variables.email) {
      newErrors.email = "メールアドレスを入力してください";
    } else if (!/\S+@\S+\.\S+/.test(variables.email)) {
      newErrors.email = "正しいメールアドレスを入力してください";
    }
    if (!variables.subject) newErrors.subject = "件名を入力してください";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    resetMessage();

    // バリデーションチェック
    e.preventDefault();
    if (validateFields()) {
      const token = localStorage.getItem('token');
      const data = {};

      if(userId !== '1') {
        data['user_id'] = userId;
        data['var1'] = variables.var1;
        data['var2'] = variables.var2;
        data['var3'] = variables.var3;
        data['var4'] = variables.var4;
        data['var5'] = variables.var5;
        data['email'] = variables.email;
        data['subject'] = variables.subject;
        data['phone_number'] = phoneNumber;
      }else {
        setError('利用者が選択されていません。');
        return;
      }

      axios.post(`${apiUrl}/api/receiving_list/inbound/`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setMessage(response.data.status);
      })
      .catch((error) => {
        if(error.response && error.response.data && error.response.data.error) {
          setError(error.response.data.error);
        }else {
          console.log(error);
          setError('登録できませんでした。');
        }
      });
    }else {
      setError('入力項目に不備があります。');
    }
  };

  return (
    <div className=" bg-white rounded-lg custom-shadow py-6">
      <h2 className='pb-4 font-semibold text-2xl text-center'>受電設定</h2>
      <div className='p-16 border-gray-300'>
        <form onSubmit={handleSubmit}>
          <div className='flex flex-col'>
            <label className='pl-2 font-semibold'>変数①　お電話ありがとうございます。【変数①】AIオペレーターと申します。</label>
            <textarea
              name='var1'
              placeholder='ユニロボット転職サービス'
              className='border-[1px] px-2 py-1 rounded-md'
              value={variables.var1}
              onChange={handleInputChange}
            />
            {errors.var1 && <div className='text-[#E83929] font-semibold'>{errors.var1}</div>}
          </div>
          <div className='flex flex-col mt-10'>
            <label className='pl-2 font-semibold'>変数②　【変数②】ただいまお時間いただいてもよろしいでしょうか。「はい」か「いいえ」でお答えください。</label>
            <textarea
              name='var2'
              placeholder='転職活動に関する状況をお伺いしたく、先ほどお電話いたしました。'
              className='border-[1px] px-2 py-1 rounded-md'
              value={variables.var2}
              onChange={handleInputChange}
            />
            {errors.var2 && <div className='text-[#E83929] font-semibold'>{errors.var2}</div>}
          </div>
          <div className='flex flex-col mt-10'>
            <label className='pl-2 font-semibold'>変数③　今お仕事されているということですが、【変数③】</label>
            <textarea
              name='var3'
              placeholder='たとえば現在よりも条件の良い求人があった場合、仕事先を変えることはご検討されますか？'
              className='border-[1px] px-2 py-1 rounded-md'
              value={variables.var3}
              onChange={handleInputChange}
            />
            {errors.var3 && <div className='text-[#E83929] font-semibold'>{errors.var3}</div>}
          </div>
          <div className='flex flex-col mt-10'>
            <label className='pl-2 font-semibold'>変数④　ありがとうございます。【変数④】</label>
            <textarea
              name='var4'
              placeholder='ご希望の条件について、改めてお話しをお伺いしたく、一度担当者からご連絡を差し上げてよろしいでしょうか。'
              className='border-[1px] px-2 py-1 rounded-md'
              value={variables.var4}
              onChange={handleInputChange}
            />
            {errors.var4 && <div className='text-[#E83929] font-semibold'>{errors.var4}</div>}
          </div>
          <div className='flex flex-col mt-10'>
            <label className='pl-2 font-semibold'>変数⑤　承知しました。【変数⑤】</label>
            <textarea
              name='var5'
              placeholder='それではまた、転職をご希望の際には、お気軽にご相談ください。それでは失礼いたします。'
              className='border-[1px] px-2 py-1 rounded-md'
              value={variables.var5}
              onChange={handleInputChange}
            />
            {errors.var5 && <div className='text-[#E83929] font-semibold'>{errors.var5}</div>}
          </div>
          <div className='flex flex-col mt-10'>
            <label className='pl-2 font-semibold'>宛先アドレス</label>
            <textarea
              name='email'
              placeholder='ai-telephone-test@unirobot.com'
              className='border-[1px] px-2 py-1 rounded-md'
              value={variables.email}
              onChange={handleInputChange}
            />
            {errors.email && <div className='text-[#E83929] font-semibold'>{errors.email}</div>}
          </div>
          <div className='flex flex-col mt-10'>
            <label className='pl-2 font-semibold'>メール件名</label>
            <textarea
              name='subject'
              placeholder='面談希望（掘り起こしAIコール）'
              className='border-[1px] px-2 py-1 rounded-md'
              value={variables.subject}
              onChange={handleInputChange}
            />
            {errors.subject && <div className='text-[#E83929] font-semibold'>{errors.subject}</div>}
          </div>
          <div className="flex justify-end items-center gap-5 pl-6 pt-5">
            <button
              className={`px-4 py-2 text-white rounded-md font-semibold ${
                userId === '1' ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#2978CA]'
              }`}
              type='submit'
              disabled={userId === '1'}
            >
              {buttonTextBlue}
            </button>
          </div>
        </form>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        accept=".csv"
        style={{ display: 'none' }}
      />
      {message && <Notification content={message} />}
      {error && <Error content={error} />}
    </div>
  );
};

export default SlotCard;
