import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Error from '../notification/error';
import Notification from '../notification/notification';

const AddUserModal = ({ editFlag, handleClose, user, setNotification, setError, isAddUserInfo }) => {
  const initialFormData = {
      user_name: '',
      user_name_kana: '',
      responsible_person_name: '',
      responsible_person_name_kana: '',
      email_address: '',
      type_code: '内科',
      is_admin: false,
      ai_call_phone_number: '',
      password: 'qweQWE123',
      memo: '',
      profile_image: '',
  };
  const [formData, setFormData] = useState([]);
  const [image, setImage] = useState(null);
  const [phoneNumberFlag, setPhoneNumberFlag] = useState(true);
  const [transfarPhoneNumberFlag, setTransfarPhoneNumberFlag] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setError(''); 
    const { name, value, type, checked } = e.target;
    if (name === 'is_admin') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        is_admin: value === '管理者',
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }

    // ひらがなの正規表現
    const kanaPattern = /^[\u3041-\u309Fー]*$/;

    if (name==='user_name' && !value) {
      setError('利用者名は必須です。');
    }
    if (name==='user_name_kana') {
      if (!value) {
        setError('利用者名(かな)は必須です。');
      }else {
        if (!value.match(kanaPattern)) {
          setError('利用者名(かな)は平仮名で入力してください。');
        }
      }
    }
    if (name==='responsible_person_name' && !value) {
      setError('担当者名は必須です。');
    }
    if (name==='responsible_person_name_kana') {
      if (!value) {
        setError('担当者名(かな)は必須です。');
      }else {
        if (!value.match(kanaPattern)) {
          setError('担当者名(かな)は平仮名で入力してください。');
        }
      }
    }
    if (name==='email_address') {
      if (!value) {
        setError('Emailは必須です。');
      }else {
        const parts = value.split("@");
        const domainParts = parts[1].split(".");
        const topLevelDomain = domainParts[domainParts.length - 1];
        if(!value.includes("@") || parts.length !== 2 || !parts[1] ||
          !parts[1].includes(".") || (topLevelDomain!=="com" && topLevelDomain!=="jp")) {
          setError('有効なメールアドレスを入力してください。');
        }
      }
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setFormData(prevData => ({
        ...prevData,
        profile_image: file
      }));
    }
  };

  const handleImageRemove = () => {
    setImage(null);
    setFormData(prevData => ({
      ...prevData,
      profile_image: ''
    }));
  };

  useEffect(() => {
    if (editFlag === true) {
      setFormData(user);
      if (user.profile_image) {
        setImage(user.profile_image);
      }
    }
    else {
      setFormData(initialFormData);
    }
    if(formData.ai_call_phone_number == undefined) formData.ai_call_phone_number = '';
    setPhoneNumber(formData.ai_call_phone_number);
  }, [editFlag]);

  useEffect(() => {
    setError('');
    var regex = /^(0[7-9]0\d{8}|050\d{8}|0[1-9]\d{8,11})$/;
    
    if(formData.transfer_phone_number!='' && formData.transfer_phone_number!=undefined){
      if((formData.transfer_phone_number).includes('-')) {
        setTransfarPhoneNumberFlag(false);
        setError('ハイフンを含めることはできません。')
      }else if(regex.test(formData.transfer_phone_number)) {
        setTransfarPhoneNumberFlag(true);
      }else {
        setTransfarPhoneNumberFlag(false);
        setError("転送先電話番号: 無効な電話番号の形式です。");
      }
    }

    if(formData.ai_call_phone_number!='' && formData.ai_call_phone_number!=undefined){
      if((formData.ai_call_phone_number).includes('-')) {
        setPhoneNumberFlag(false);
        setError('ハイフンを含めることはできません。')
      }else if(regex.test(formData.ai_call_phone_number)) {
        setPhoneNumberFlag(true);
      }else {
        setPhoneNumberFlag(false); // 不正な場合
        setError("AICall Phone Number: 無効な電話番号の形式です。");
      }
    }
  }, [formData.ai_call_phone_number, formData.transfer_phone_number]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const form = new FormData();

    Object.keys(formData).forEach(key => {
      if (key === 'profile_image') {
        if (image) {
          // If a new file is selected, append it to the form
          if (typeof formData[key] === 'object' && formData[key] !== null) {
              form.append(key, formData[key]);
          }
        } else if (editFlag && !image && formData[key] === '') {
          // If attempting to delete an existing profile image
          // Append a special flag or null to indicate deletion
          form.append(key, ''); // or null if your backend handles it as such
        }
      } else {
        form.append(key, formData[key]);
      }
    });

    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
  
      let response;
      if (editFlag) {
        if(!transfarPhoneNumberFlag || !phoneNumberFlag) {
          setError('転送先電話番号またはAICall Phone Numberの形式を確認してください。')
          return;
        }
        response = await axios.put(`/api/users/${user.user_id}/`, form, config);
        if(response && response.data && response.data.error) {
          setError(response.data.error);
          return;
        }
        setNotification('更新されました。');  // 更新時の通知メッセージ
        if(phoneNumberFlag && phoneNumber !== formData.ai_call_phone_number){
          if(formData.ai_call_phone_number !== '') {
            hendlePhoneNumberRegister();
          }else {
            hendlePhoneNumberDelete();  // 編集時に登録されている電話番号が空入力に変更された場合
          }
        }
        handleClose();  // モーダルを閉じる
      } else {
        response = axios.post(`/api/users/`, form, config)
        if(response && response.data && response.data.error) {
          setError(response.data.error);
          return;
        }
        setNotification('登録されました。');  // 更新時の通知メッセージ;
      }
    }catch(error) {
      console.log('Error: ', error);
      if(error.response && error.response.data) {
        setError("既に登録されているメールアドレスです。");
      }else {
        setError('登録または更新できませんでした。');
      }
    }
  };

  const hendlePhoneNumberRegister = async() => {
    const token = localStorage.getItem('token');
    const form = new FormData();
    Object.keys(formData).forEach(key => {
      if (key === 'profile_image') {
        if (image) {
          // If a new file is selected, append it to the form
          if (typeof formData[key] === 'object' && formData[key] !== null) {
              form.append(key, formData[key]);
          }
        } else if (editFlag && !image && formData[key] === '') {
          // If attempting to delete an existing profile image
          // Append a special flag or null to indicate deletion
          form.append(key, ''); // or null if your backend handles it as such
        }
      } else {
        form.append(key, formData[key]);
      }
    });

    // phone_numberテーブルへの登録・更新
    const params = {};
    params['user_id'] = formData.user_id;
    params['ai_call_phone_number'] = formData.ai_call_phone_number;
    try {
      const response = await axios.post(`/api/phone_number/register/`, params, {
        headers: { 
          Accept: "application/json",
          Authorization: `Bearer ${token}` 
        },
      });
    } catch (error) {
      if(error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      }else {
        setError('電話番号の更新中にエラーが発生しました。');
      }
    }
    setPhoneNumberFlag(false);
  };

  const hendlePhoneNumberDelete = async() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      params: {
        user_id: formData.user_id,
      }
    };

    axios.delete(`/api/phone_number/delete/`, config)
      .then(response => {
        if (response.status === 200) {
          console.log("電話番号が正常に削除されました。");
        } else {
          setError('電話番号の削除中に予期せぬエラーが発生しました。');
        }
      }
    )
    .catch(error => {
      console.error("Error: ", error);
      setError("電話番号の削除に失敗しました。", error);
    });
    setPhoneNumberFlag(false);
  }

  return (
    <>
      <button onClick={handleClose} className="absolute top-1 right-4 text-gray-600 text-3xl">
        &times;
      </button>
      <h2 className="text-xl font-bold mb-4">
        { isAddUserInfo ? '利用者情報追加' : '利用者情報編集' }
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center mb-4">
          <div className="relative">
            <input
              type="file"
              name='profile_image'
              accept="image/*"
              onChange={handleImageChange}
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            />
            {image ? (
              <>
                <img
                  src={image}
                  alt="User"
                  className="w-16 h-16 rounded-full object-cover"
                />
                <button
                  type="button"
                  onClick={handleImageRemove}
                  className="text-red-600 w-6 h-6 text-center align-middle absolute top-0 right-0 bg-white rounded-full"
                >
                  &times;
                </button>
              </>
            ) : (
              <div className="w-16 h-16 bg-blue-500 text-white flex items-center justify-center rounded-full">
                <span className='text-center'>Add image</span>
              </div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label className="block text-md text-blue-700 font-semibold">利用者名</label>
            <input
              type="text"
              name="user_name"
              placeholder='XXX'
              value={formData.user_name}
              required
              novalidate
              onChange={handleChange}
              className=" outline-[1px] outline-gray-700 mt-2 p-2 block w-full shadow-sm md:text-[18px] sm:text-sm border-[1px] border-gray-300 hover:border-blue-500 active:border-blue-500 focus:border-blue-500 rounded-md"
            />
          </div>
          <div className="mb-4">
            <label className="block text-md text-blue-700 font-semibold">担当者名</label>
            <input
              type="text"
              name="responsible_person_name"
              placeholder='XXXX'
              value={formData.responsible_person_name}
              required
              novalidate
              onChange={handleChange}
              className="outline-[1px] outline-gray-700 mt-2 p-2 block w-full shadow-sm md:text-[18px] sm:text-sm border-[1px]  border-gray-300 hover:border-blue-500 active:border-blue-500 focus:border-blue-500 rounded-md"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label className="block text-md text-blue-700 font-semibold">利用者名(かな)</label>
            <input
              type="text"
              name="user_name_kana"
              placeholder='XXX'
              value={formData.user_name_kana}
              required
              novalidate
              onChange={handleChange}
              className=" outline-[1px] outline-gray-700 mt-2 p-2 block w-full shadow-sm md:text-[18px] sm:text-sm border-[1px] border-gray-300 hover:border-blue-500 active:border-blue-500 focus:border-blue-500 rounded-md"
            />
          </div>
          <div className="mb-4">
            <label className="block text-md text-blue-700 font-semibold">担当者名(かな)</label>
            <input
              type="text"
              name="responsible_person_name_kana"
              placeholder='XXXX'
              value={formData.responsible_person_name_kana}
              required
              novalidate
              onChange={handleChange}
              className="outline-[1px] outline-gray-700 mt-2 p-2 block w-full shadow-sm md:text-[18px] sm:text-sm border-[1px]  border-gray-300 hover:border-blue-500 active:border-blue-500 focus:border-blue-500 rounded-md"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label className="block text-md text-blue-700 font-semibold">Email</label>
            <input
              type="email"
              name="email_address"
              placeholder='XXXXX@XXXX.XX.XX'
              value={formData.email_address}
              onChange={handleChange}
              required
              novalidate
              className="outline-[1px] outline-gray-700 mt-2 p-2 block w-full shadow-sm md:text-[18px] sm:text-sm border-[1px]  border-gray-300 hover:border-blue-500 active:border-blue-500 focus:border-blue-500 rounded-md"
            />
          </div>
          {/* <div className="mb-4">
            <label className="block text-md text-blue-700 font-semibold">種別</label>
            <select
              name="type_code"
              value={formData.type_code}
              onChange={handleChange}
              className="mt-2 p-2 block w-full shadow-sm md:text-[16px] sm:text-sm border-[1px]  border-gray-300 hover:border-blue-500 active:border-blue-500 focus:border-blue-500 rounded-md"
            >
              <option value="内科">内科</option>
              <option value="外科">外科</option>
              <option value="耳鼻科">耳鼻科</option>
            </select>
          </div> */}
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label className="block text-md text-blue-700 font-semibold">権限</label>
            <select
              name="is_admin"
              value={formData.is_admin ? '管理者' : '利用者'}
              onChange={handleChange}
              className="mt-2 p-2 block w-full shadow-sm md:text-[16px] sm:text-sm border-[1px]  border-gray-300 hover:border-blue-500 active:border-blue-500 focus:border-blue-500 rounded-md"
            >
              <option value="利用者">利用者</option>
              <option value="管理者">管理者</option>
            </select>
          </div>
          <div className="flex items-center">
            <label className="block text-md text-blue-700 font-semibold mr-4">アカウント有効</label>
            <input
              type="checkbox"
              name="is_valid"
              checked={formData.is_valid}
              onChange={handleChange}
              className="h-4 w-4 text-blue-600 border-gray-300 hover:border-blue-500 active:border-blue-500 focus:border-blue-500 rounded"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label className="block text-md text-blue-700 font-semibold">転送先電話番号</label>
            <input
              type="text"
              name="transfer_phone_number"
              placeholder='XXXXXXXXXXX(ハイフンなし)'
              value={formData.transfer_phone_number}
              onChange={handleChange}
              className="outline-[1px] outline-gray-700 mt-2 p-2 block w-full shadow-sm md:text-[18px] sm:text-sm border-[1px]  border-gray-300 hover:border-blue-500 active:border-blue-500 focus:border-blue-500 rounded-md"
            />
          </div>
          <div className="flex items-center justify-between mr-4">
            <div className="flex items-center">
              <label className="block text-md text-blue-700 font-semibold mr-4">転送有効</label>
              <input
                type="checkbox"
                name="is_transfar"
                checked={formData.is_transfar}
                onChange={handleChange}
                className="h-4 w-4 text-blue-600 border-gray-300 hover:border-blue-500 active:border-blue-500 focus:border-blue-500 rounded"
              />
            </div>
            <div className="flex items-center">
              <label className="block text-md text-blue-700 font-semibold mr-4">日程調整(シナリオ) 有効</label>
              <input
                type="checkbox"
                name="is_schedule_adjustment"
                checked={formData.is_schedule_adjustment}
                onChange={handleChange}
                className="h-4 w-4 text-blue-600 border-gray-300 hover:border-blue-500 active:border-blue-500 focus:border-blue-500 rounded"
              />
            </div>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-md text-blue-700 font-semibold">AICall Phone Number</label>
          <input
            type="text"
            name="ai_call_phone_number"
            placeholder='XXXXXXXXXXX(ハイフンなし)'
            value={formData.ai_call_phone_number}
            onChange={handleChange}
            className="outline-[1px] outline-gray-700 mt-2 p-2 block w-full shadow-sm md:text-[18px] sm:text-sm border-[1px]  border-gray-300 hover:border-blue-500 active:border-blue-500 focus:border-blue-500 rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-md text-blue-700 font-semibold">備考</label>
          <textarea
            name="memo"
            value={formData.memo}
            onChange={handleChange}
            className="outline-[1px] outline-gray-700 mt-2 p-2 block w-full shadow-sm md:text-[18px] sm:text-sm border-[1px]  border-gray-300 hover:border-blue-500 active:border-blue-500 focus:border-blue-500 rounded-md"
          ></textarea>
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
            onClick={handleClose}>
            キャンセル
          </button>
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md ">保存</button>
        </div>
      </form>
    </>
  );
};

export default AddUserModal;