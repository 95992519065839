import * as React from 'react';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import 'dayjs/locale/ja';
import dayjs from 'dayjs';
import { FaCalendarAlt } from "react-icons/fa";

const CustomCalendarIcon = (props) => {
  return <FaCalendarAlt style={{ fontSize: props.size }} />;
};

const CustomDatePicker = ({ selected, onChange, label }) => {
  dayjs.locale('ja');

  function Label({ componentName, valueType, isProOnly }) {
    const content = (
      <span style={{ fontFamily: 'Noto Sans JP'}}>
        <strong>{componentName}</strong>
      </span>
    );
    return content;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
      <DemoContainer components={['DatePicker']}>
        <DemoItem label={<Label componentName={label} />}>
          <DatePicker
            value={selected}
            onChange={(date) => onChange(date)}
            format="YYYY/MM/DD"
            slots={{ openPickerIcon: CustomCalendarIcon }}
            slotProps={{
              openPickerIcon: { size: '18px' },
              textField: {
                sx: {
                  '& input': {
                    height: '29px',
                    width: '140px',
                    padding: '14px',
                  },
                  backgroundColor: 'white',
                }
              },
              calendarHeader: {
                format: 'YYYY年 M月'
              }
            }}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}

export default CustomDatePicker;
