import React from 'react';
import { FaCloudDownloadAlt } from "react-icons/fa";
import { downloadCSV } from '../../utils/csvUtils.js';
const DownloadCSV = ({ data, btntext, disabled }) => {
    const handleDownload = () => {
        downloadCSV(data);
    };

    return (
        <button
            onClick={handleDownload}
            title="CSVダウンロード"
            className={`flex items-center mt-4 px-4 py-7 border-[1px] border-gray-300 rounded-md gap-2 max-h-11 ${disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-white'}`}
            disabled={disabled}>
            <FaCloudDownloadAlt size={26} />
            { btntext=='' ? '' : <span className=' font-semibold'>{btntext}</span> }
        </button>
    );
};

export default DownloadCSV;