import React from "react";
import { Route, Routes } from "react-router-dom";
import RootLayout from "./RootLayout";
import InitPage from "./InitPage";
import ResultPage from "./Result";
import CallSettingPage from "./CallSettingPage";
import UserManagement from "./UserManagement";
import ProtectedRoute from "../../component/ProtectedRoute";
import { TimeProvider } from "../../context/TimeContext";

const DashboardRouter = () => {
  return (
    <TimeProvider>
      <RootLayout>
        <Routes>
          <Route path="/" element={<InitPage/>} />
          <Route path="/call-setting" element={<ProtectedRoute component={CallSettingPage} />}  />
          <Route path="/result" element={<ResultPage/>} />
          <Route path="/user-info" element={<ProtectedRoute component={UserManagement} allowedRoles={[true]} />}/>
        </Routes>
      </RootLayout>
    </TimeProvider>
  );
};

export default DashboardRouter;



