import React, { createContext, useState} from "react";

export const TimeContext = createContext();

export const TimeProvider = ({children}) => {
  const [startHour, setStartHour] = useState('');
  const [startMinute, setStartMinute] = useState('');

  return (
    <TimeContext.Provider value={{ startHour, setStartHour, startMinute, setStartMinute }}>
      {children}
    </TimeContext.Provider>
  );
};