import SearchInput from '../../component/dashboard/SearchInput';
import Container from '../../component/dashboard/Container';
import GridBox from '../../component/dashboard/GridBox';
import ChartContacts from '../../component/dashboard/ChartContacts';
import ChartInterviews from '../../component/dashboard/ChartInterviews';
import ChartCalls from '../../component/dashboard/ChartCalls';
import DetailsTable from '../../component/dashboard/DetailsTable';
import { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import Error from '../../component/notification/error';

const InitPage=()=> {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [data, setData] = useState({
    total_call_type: 0,
    total_contact: 0,
    total_interview: 0,
    rate_contact: 0,
    rate_interview_contact: 0,
    rate_interview_call_type: 0,
  });
  const [userId, setUserId] = useState('0');  // 利用者
  const [startDate, setStartDate] = useState(null); // 開始日
  const [endDate, setEndDate] = useState(null); // 終了日
  const [typeCall, setTypeCall] = useState('合計 (架電数 + 受電数)');
  const [error, setError] = useState(""); // エラーハンドリング用
  const [isInitialLoad, setIsInitialLoad] = useState(true); // 初回ロードかどうかのフラグ

  useEffect(() => {
    if(isInitialLoad) {
      const today = new Date();
      const firstDayOfMonth = dayjs(new Date(today.getFullYear(), today.getMonth(), 1));  // 月の1日
      const lastDayOfMonth = dayjs(new Date(today.getFullYear(), today.getMonth()+1, 0));    // 月の最終日

      setStartDate(firstDayOfMonth);
      setEndDate(lastDayOfMonth);
      setIsInitialLoad(false);
    }
  }, [isInitialLoad]);  // 初回のみ

  useEffect(() => {
    if(!startDate || !endDate) return;
    const token = localStorage.getItem('token');

    const params = {};
    // ユーザーIDのフィルタを追加
    if(userId) params['user_id'] = userId;

    // 日付の検証とエラー表示
    if (startDate && endDate && startDate > endDate) {
      setError('日付(FROM)は日付(TO)以前を設定してください。');
    }

    if (startDate) params['start_date'] = dayjs(startDate).format('YYYY-MM-DD');
    if (endDate) params['end_date'] = dayjs(endDate).format('YYYY-MM-DD');

    if (typeCall) params['call_type'] = typeCall;

    axios.get(`${apiUrl}/api/call_result/total/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: params
    })
    .then(response => {
      setData(response.data); // オブジェクト配列全体をセット
    })
    .catch((error) => {
      console.log("Error: ", error);
      localStorage.removeItem('token');
      setError("通話情報の取得に失敗しました。")
    });
  }, [userId, startDate, endDate, typeCall]);
  
  const handleSearchParamsChange = (newParams) => {
    setError('');
    handleUserIdChange(newParams.userId);
    handleStartDateChange(newParams.startDate);
    handleEndDateChange(newParams.endDate);
    handleTypeCallChange(newParams.typeCall);
  }

  // ユーザーIDの設定
  const handleUserIdChange = (userid) => {
    setUserId(userid);
  };
  // 日付の設定
  const handleStartDateChange = (date) => {
    // JSTの日付を取得
    if(date && !(date===startDate)) {
      const jstDate = new Date(date);
      jstDate.setHours(jstDate.getHours() + 9); // JSTはUTC+9
      date = jstDate.getFullYear() + '-' + (jstDate.getMonth()+1) + '-' + jstDate.getDate();  // + ' ' + jstDate.getHours() + ':' + jstDate.getMinutes();
      const value = dayjs(date);
      setStartDate(value);
    }
  };  
  const handleEndDateChange = (date) => {
    if(date && !(date===endDate)) {
      const jstDate = new Date(date);
      jstDate.setHours(jstDate.getHours() + 9); // JSTはUTC+9
      date = jstDate.getFullYear() + '-' + (jstDate.getMonth()+1) + '-' + jstDate.getDate();  // + ' ' + jstDate.getHours() + ':' + jstDate.getMinutes();
      const value = dayjs(date);
      setEndDate(value);
    }
  };
  const handleTypeCallChange = (typecall) => {
    setTypeCall(typecall);
  };

  return (
    <Container className="bg-[#f8f8fb] h-full flex-1">
      <SearchInput
        selected={userId}
        startDate={startDate}
        endDate={endDate}
        typeCall={typeCall}
        onChange={handleSearchParamsChange} />
      <div>
        <div className=' grid grid-cols-1 sm:grid-cols-1 md:grid-cols-6 gap-8'>
          <GridBox
            headerText={typeCall === '架電数' ? '架電数'
                        : typeCall === '受電数' ? '受電数' : '架電数・受電数'}
            contentText={data.total_call_type}
            contentFontSize="30px"
          />
          <GridBox
            headerText="コンタクト数"
            contentText={data.total_contact}
            contentFontSize="30px"
          />
          <GridBox
            headerText="面談希望数"
            contentText={data.total_interview}
            contentFontSize="30px"
          />
          <GridBox
            headerText="コンタクト率"
            contentText={`${data.rate_contact.toFixed(1)}%`}
            sub_text={typeCall === '架電数' ? 'コンタクト数 / 架電数'
              : typeCall === '受電数' ? 'コンタクト数 / 受電数' : 'コンタクト数 / (架電数・受電数)'}
            contentFontSize="30px"
          />
          <GridBox
            headerText="面談希望率①"
            contentText={`${data.rate_interview_contact.toFixed(1)}%`}
            sub_text="面談希望数 / コンタクト数"
            contentFontSize="30px"
          />
          <GridBox
            headerText="面談希望率②"
            contentText={`${data.rate_interview_call_type.toFixed(1)}%`}
            sub_text={typeCall === '架電数' ? '面談希望数 / 架電数'
              : typeCall === '受電数' ? '面談希望数 / 受電数' : '面談希望数 / (架電数・受電数)'}
            contentFontSize="30px"
          />
        </div>
      </div>
      <div className=' grid grid-cols-3 gap-8 mt-8'>
        <ChartCalls
          selected={userId}
          startDate={startDate}
          endDate={endDate}
          typeCall={typeCall}
        />
        <ChartContacts
          selected={userId}
          startDate={startDate}
          endDate={endDate}
          typeCall={typeCall}
        />
        <ChartInterviews
          selected={userId}
          startDate={startDate}
          endDate={endDate}
          typeCall={typeCall}
        />
      </div>
      <div className='mt-8'>
        <DetailsTable
          selected={userId}
          startDate={startDate}
          endDate={endDate}
          typeCall={typeCall}
        />
      </div>
      <Error content={error} />
    </Container>
  );
}

export default InitPage;
