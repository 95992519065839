import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Error from '../component/notification/error';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = sessionStorage.getItem('isAuthenticated');
    if(!isAuthenticated) {
      logout();
    }
  }, []);

  useEffect(() => {
    const fetchUser = () => {
      const token = localStorage.getItem('token');
      if (token) {
        axios.get('/api/authentication/user-role/', {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          setUser(response.data);
        })
        .catch(error => {
          if(error.response?.status === 401) {
            localStorage.removeItem('token');
            logout();
          }else {
            console.error('Error fetching user:', error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
      } else {
        setLoading(false);

      }
    };

    fetchUser();
  }, [navigate]);

  const login = (email, password) => {
    let remember = false;

    // 認証処理が成功した場合
    sessionStorage.setItem('isAuthenticated', 'true');

    setError('');

    // メールアドレスのバリデーション
    if(!email) {
      setError('アカウントID（メールアドレス）は必須です。');
      return;
    }
    const parts = email.split("@");
    const domainParts = parts[1].split(".");
    const topLevelDomain = domainParts[domainParts.length - 1];
    if(!email.includes("@") || parts.length !== 2 || !parts[1] ||
      !parts[1].includes(".") || (topLevelDomain!=="com" && topLevelDomain!=="jp")) {
      setError('有効なメールアドレスを入力してください。');
      return;
    }
    // パスワードの入力チェック
    if(!password) {
      setError('パスワード必須です。');
      return;
    }
    axios.post('/api/authentication/login/', { email, password })
      .then(response => {
        const { token } = response.data.result;
        localStorage.setItem('token', token);
        remember = response.data.result.user.remember;
        return axios.get('/api/authentication/user-role/', {
          headers: { Authorization: `Bearer ${token}` }
        });
      })
      .then(userResponse => {
        setUser(userResponse.data);

        if(!remember) {
          navigate("/verify-code");
        }else {
          navigate("/dashboard");
        }
      })
      .catch(error => {
        console.error('Login failed:', error);
        setError(error.response?.data?.error || "ログイン失敗！もう一度お試しください");
      });
  };

  const logout = () => {
    const isAuthenticated = sessionStorage.getItem('isAuthenticated');
    if(isAuthenticated) {
      navigate('/login');
      sessionStorage.removeItem('isAuthenticated');
    }
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, login, logout, loading }}>
      {children}
      <Error content={error} />
    </UserContext.Provider>
  );
};
