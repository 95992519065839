import React, { useEffect, useState, useContext } from 'react';
import DownloadCSV from './DownloadCSV';
import CustomDatePicker from './CustomDatePicker';
import { IconButton, TablePagination } from '@mui/material';
import { IoPlay } from "react-icons/io5";
import { UserContext } from '../../context/UserContext';
import SelectUser from '../../component/dashboard/SelectUser';
import SelectContact from '../../component/dashboard/SelectContact';
import axios from 'axios';
import Error from "../../component/notification/error";
import dayjs from 'dayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { MdOutlineSort } from "react-icons/md";
import { Modal as BaseModal } from '@mui/base/Modal';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';

import { gapi } from 'gapi-script';
import { useGoogleLogin } from '@react-oauth/google';
import AudioPlayer from './AudioPlayer';
import { FaCloudDownloadAlt } from "react-icons/fa";

const ResultTable = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const { user } = useContext(UserContext);
  const [data, setData] = useState([]); // テーブルデータ
  const [outboundList, setOutBoundList] = useState([]);
  const [userId, setUserId] = useState('0');  // 利用者
  const [interviewAvailability, setInterview] = useState('すべて');  // 利用者
  const [startDate, setStartDate] = useState(null); // 開始日
  const [endDate, setEndDate] = useState(null); // 終了日
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [isFocused, setIsFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState([]); // 複数カラムのソート設定
  const [isInitialLoad, setIsInitialLoad] = useState(true); // 初回ロードかどうかのフラグ
  const [open, setOpen] = useState(false);
  const [selectVoiceUrl, setSelectVoiceUrl] = useState();
  
  const [error, setError] = useState(""); // エラーハンドリング用
  const [isExternalDisplay, setIsExternalDisplay] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    // 解像度やスクリーンサイズで外部モニターかどうかを判定
    const detectExternalDisplay = () => {
      const { width, height } = window.screen; // 画面の幅と高さ
      const ratio = window.devicePixelRatio; // DPIスケーリング
      setIsExternalDisplay(width > 1920 || (width >= 1440 && height >= 900 && ratio === 1));
    };

    detectExternalDisplay();

    // ウィンドウリサイズ時にもチェック
    window.addEventListener("resize", detectExternalDisplay);
    return () => window.removeEventListener("resize", detectExternalDisplay);
  }, []);

  useEffect(() => {
    if(isInitialLoad) {
      const today = new Date();
      const firstDayOfMonth = dayjs(new Date(today.getFullYear(), today.getMonth(), 1));  // 月の1日
      const lastDayOfMonth = dayjs(new Date(today.getFullYear(), today.getMonth()+1, 0));    // 月の最終日

      setStartDate(firstDayOfMonth);
      setEndDate(lastDayOfMonth);
      setIsInitialLoad(false);

      if(!user.is_admin) {
        setUserId(user.user_id);
      }
    }
  }, [isInitialLoad]);  // 初回のみ

  useEffect(() => {
    if(!startDate || !endDate) return;
    const token = localStorage.getItem('token');

    const params = {};
    // ユーザーIDのフィルタを追加
    if(userId) params['user_id'] = userId;
    // 面談可否のフィルタを追加
    if(interviewAvailability) params['interview_availability'] = interviewAvailability;
    // 日付フィルタのパラメータを追加
    if (startDate && endDate && (startDate > endDate)) {
      setError('日付(FROM)は日付(TO)以前を設定してください。');
    }

    if (startDate) params['start_date'] = dayjs(startDate).format('YYYY-MM-DD');
    if (endDate) params['end_date'] = dayjs(endDate).format('YYYY-MM-DD');

    axios.get(`${apiUrl}/api/call_result/callResult/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: params
    })
    .then(response => {
      if (Array.isArray(response.data)) { // response.dataが配列か確認
        setData(response.data); // オブジェクト配列全体をセット
      }
    })
    .catch((error) => {
      console.log("Error: ", error);
      setError("通話結果情報の取得に失敗しました。")
    });

    // 架電NGの人に対して、再度架電リスト作成の際に使用するデータ（CSVダウロード用）
    axios.get(`${apiUrl}/api/call_result/outboundList/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: params
    })
    .then(response => {
      setOutBoundList(response.data); // オブジェクト配列全体をセット
    })
    .catch((error) => {
      console.log("Error: ", error);
    });
  }, [apiUrl, startDate, endDate, interviewAvailability, userId]); // apiUrl, startDate, endDateが変更されるたびに実行


  // ソート用の関数
  const handleSort = (key) => {
    let newSortConfig = [...sortConfig];
    const existingIndex = newSortConfig.findIndex((config) => config.key === key);
    if(existingIndex !== -1) {
      // ソート方向の変更（昇順→降順→元の状態）
      if(newSortConfig[existingIndex].direction === 'asc') {
        newSortConfig[existingIndex].direction = 'desc';
      }else {
        newSortConfig.splice(existingIndex, 1); // すでに降順の場合は元の状態に戻す
      }
    }else {
      // 先に選択した項目の優先順位を高くする
      newSortConfig.push({ key, direction: 'asc' });
    }

    setSortConfig(newSortConfig);
  };
  // ソートされたデータ
  const sortedData = React.useMemo(() => {
    if(sortConfig.length === 0) return data; // ソートなしの場合そのまま返す

    const defaultSort = (a, b) => {
      if(a.id < b.id) return -1;
      if(a.id > b.id) return 1;
      return 0;
    };

    return [...data].sort((a, b) => {
      for(let i=0; i<sortConfig.length; i++) {
        const { key, direction } = sortConfig[i];

        if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
        if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
        // 同じ値なら次のソート条件に進む
      }
      return defaultSort(a, b); // すべての条件が同じ場合は固定ソートを適用
    });
  }, [data, sortConfig]);
  const resetSorting = () => {
    setSortConfig([]);
  };

  // ユーザーIDの設定
  const handleUserIdChange = (userid) => {
    setError('');
    setUserId(userid);
  };
  // 面談可否の設定
  const handleInterviewChange = (interviewavailability) => {
    setError('');
    setInterview(interviewavailability);
  };
  // 日付の設定
  const handleStartDateChange = (date) => {
    // JSTの日付を取得
    if(date && !(date===startDate)) {
      const jstDate = new Date(date);
      jstDate.setHours(jstDate.getHours() + 9); // JSTはUTC+9
      date = jstDate.getFullYear() + '-' + (jstDate.getMonth()+1) + '-' + jstDate.getDate();  // + ' ' + jstDate.getHours() + ':' + jstDate.getMinutes();
      const value = dayjs(date);
      setStartDate(value);
    }
  };  
  const handleEndDateChange = (date) => {
    if(date && !(date===endDate)) {
      const jstDate = new Date(date);
      jstDate.setHours(jstDate.getHours() + 9); // JSTはUTC+9
      date = jstDate.getFullYear() + '-' + (jstDate.getMonth()+1) + '-' + jstDate.getDate();  // + ' ' + jstDate.getHours() + ':' + jstDate.getMinutes();
      const value = dayjs(date);
      setEndDate(value);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
  };

  // オーディオダイアログの管理
  const handleAudioSet = async(url) => {
    setSelectVoiceUrl(url);
    setOpen(true);
    setError('');
  }
  const handleClose = () => {
    setOpen(false);
  };


  const filteredData = sortedData ? sortedData.filter(data => {
    const queries = searchQuery.toLowerCase().split(' '); // 検索クエリをスペース区切りで分割
    return queries.every(query =>
      data.phone_number.toLowerCase().includes(query) ||
      data.contact.toLowerCase().includes(query) ||
      data.phone_availability.toLowerCase().includes(query) ||
      data.working_existence?.toLowerCase().includes(query) ||
      data.job_change_availability?.toLowerCase().includes(query) ||
      data.interview_availability?.toLowerCase().includes(query) ||
      data.interview_desired_md?.toLowerCase().includes(query) ||
      data.interview_desired_time?.toLowerCase().includes(query) ||
      data.call_type?.toLowerCase().includes(query) ||
      data.call_time?.toLowerCase().includes(query)
    );
  }): [];

  function Label({ componentName, valueType, isProOnly }) {
    const content = (
      <span style={{ fontFamily: 'Noto Sans JP' }}>
        <strong>{componentName}</strong>
      </span>
    );
    return content;
  }

  return (
    <div>
      <div className="flex justify-around items-center mb-6">
        {user?.is_admin ?
          (<SelectUser onChange={handleUserIdChange} user='0' page='callResult' />)
          :null
        }
        <div>
          <SelectContact onChange={handleInterviewChange} />
        </div>

        <div className='my-4 mt-5'>
          <DemoItem label={<Label componentName='絞り込み検索' />}>
            <div className="relative w-64 pr-4">
              <input
                type="text"
                className="w-full py-2 pl-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                style={{ height: '57px' }}
                label='絞り込み検索'
                value={searchQuery}
                onChange={handleSearchChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
              {!isFocused && !searchQuery && (
                <div className='absolute left-2 top-2 text-gray-400 flex items-center'>
                  <div className="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                  <p className='text-sm items-center'>複数条件入力可<br />(半角スペース区切り)</p>
                </div>
              )}
            </div>
          </DemoItem>
        </div>

        <div className="flex gap-2 my-4 mt-3">
          <CustomDatePicker label={"日付 (FROM)"} selected={startDate} onChange={handleStartDateChange} />
          <CustomDatePicker label={"日付 (TO)"} selected={endDate} onChange={handleEndDateChange} />
        </div>

        <div className='flex gap-2 mt-8'>
          <button
            onClick={resetSorting}
            title="ソートのリセット"
            className="flex items-center bg-white my-4 px-4 py-7 border-[1px] border-gray-300 rounded-md gap-2 max-h-11">
            <MdOutlineSort size={26} />
            {isExternalDisplay && <span className="font-semibold">ソートのリセット</span>}
          </button>
          {/* {
            isExternalDisplay ?
              <DownloadCSV data={data} btntext={"CSVダウンロード"} /> : <DownloadCSV data={data} btntext={""} />
          } */}
          <div
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
            className="relative inline-block text-left"
          >
            <button className="flex items-center bg-white my-4 px-4 py-7 border-[1px] border-gray-300 rounded-md gap-2 max-h-11">
              <FaCloudDownloadAlt size={26} />
              {isExternalDisplay ? 'CSVダウンロード' : null}
            </button>
            {showDropdown && (
              <div className="dropdown-menu absolute z-10 right-0 w-56 pb-4 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <div className="py-1 flex flex-col items-center justify-center">
                  <DownloadCSV
                    className="dropdown-item block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    data={data} btntext={"通話結果データ　"}
                  >
                    通話結果
                  </DownloadCSV>
                  <DownloadCSV
                    className="dropdown-item block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    data={outboundList} btntext={"架電リスト作成用"}
                    disabled={ userId === '0'}
                  >
                    架電リスト
                  </DownloadCSV>
                  { userId==='0' ? <p className='mt-1 text-sm text-red-500'>利用者を選択してください</p> : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='rounded-md max-h-[calc(100vh-320px)] overflow-auto'>
        <table className="min-w-full bg-white ">
          <thead className="bg-gray-200 text-gray-600 sticky top-0 z-8">
            <tr>
              <th className="py-2 cursor-pointer" onClick={() => handleSort('phone_number')}>
                電話番号
                {sortConfig.find(config => config.key === 'phone_number') ?
                  (sortConfig.find(config => config.key === 'phone_number').direction === 'desc' ? ' ▼' :
                  (sortConfig.find(config => config.key === 'phone_number').direction === 'asc' ? ' ▲' : null)) : ''
                }
              </th>
              <th className="py-2 cursor-pointer" onClick={() => handleSort('contact')}>
                コンタクト
                {sortConfig.find(config => config.key === 'contact') ?
                  (sortConfig.find(config => config.key === 'contact').direction === 'desc' ? ' ▼' :
                  (sortConfig.find(config => config.key === 'contact').direction === 'asc' ? ' ▲' : null)) : ''
                }
              </th>
              <th className="py-2 cursor-pointer" onClick={() => handleSort('phone_availability')}>
                電話可否
                {sortConfig.find(config => config.key === 'phone_availability') ?
                  (sortConfig.find(config => config.key === 'phone_availability').direction === 'desc' ? ' ▼' :
                  (sortConfig.find(config => config.key === 'phone_availability').direction === 'asc' ? ' ▲' : null)) : ''
                }
              </th>
              <th className="py-2 cursor-pointer" onClick={() => handleSort('working_existence')}>
                就業有無
                {sortConfig.find(config => config.key === 'working_existence') ?
                  (sortConfig.find(config => config.key === 'working_existence').direction === 'desc' ? ' ▼' :
                  (sortConfig.find(config => config.key === 'working_existence').direction === 'asc' ? ' ▲' : null)) : ''
                }
              </th>
              <th className="py-2 cursor-pointer" onClick={() => handleSort('job_change_availability')}>
                転職可否
                {sortConfig.find(config => config.key === 'job_change_availability') ?
                  (sortConfig.find(config => config.key === 'job_change_availability').direction === 'desc' ? ' ▼' :
                  (sortConfig.find(config => config.key === 'job_change_availability').direction === 'asc' ? ' ▲' : null)) : ''
                }
              </th>
              <th className="py-2 cursor-pointer" onClick={() => handleSort('interview_availability')}>
                面談可否
                {sortConfig.find(config => config.key === 'interview_availability') ?
                  (sortConfig.find(config => config.key === 'interview_availability').direction === 'desc' ? ' ▼' :
                  (sortConfig.find(config => config.key === 'interview_availability').direction === 'asc' ? ' ▲' : null)) : ''
                }
              </th>
              <th className="py-2 cursor-pointer" onClick={() => handleSort('interview_desired_md')}>
                希望月日
                {sortConfig.find(config => config.key === 'interview_desired_md') ?
                  (sortConfig.find(config => config.key === 'interview_desired_md').direction === 'desc' ? ' ▼' :
                  (sortConfig.find(config => config.key === 'interview_desired_md').direction === 'asc' ? ' ▲' : null)) : ''
                }
              </th>
              <th className="py-2 cursor-pointer" onClick={() => handleSort('interview_desired_time')}>
                希望時間
                {sortConfig.find(config => config.key === 'interview_desired_time') ?
                  (sortConfig.find(config => config.key === 'interview_desired_time').direction === 'desc' ? ' ▼' :
                  (sortConfig.find(config => config.key === 'interview_desired_time').direction === 'asc' ? ' ▲' : null)) : ''
                }
              </th>
              <th className="py-2 cursor-pointer" onClick={() => handleSort('call_type')}>
                通話種別
                {sortConfig.find(config => config.key === 'call_type') ?
                  (sortConfig.find(config => config.key === 'call_type').direction === 'desc' ? ' ▼' :
                  (sortConfig.find(config => config.key === 'call_type').direction === 'asc' ? ' ▲' : null)) : ''
                }
              </th>
              <th className="py-2 cursor-pointer" onClick={() => handleSort('call_time')}>
                通話日時
                {sortConfig.find(config => config.key === 'call_time') ?
                  (sortConfig.find(config => config.key === 'call_time').direction === 'desc' ? ' ▼' :
                  (sortConfig.find(config => config.key === 'call_time').direction === 'asc' ? ' ▲' : null)) : ''
                }
              </th>
              <th className="py-2">音声</th>
            </tr>
          </thead>
          <tbody>
            {/* {data.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row, index) => ( */}
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <tr key={index} className="text-center">
                  <td className="py-2 border-b">{row.phone_number}</td>
                  <td className="py-2 border-b">{row.contact}</td>
                  <td className="py-2 border-b">{row.phone_availability}</td>
                  <td className="py-2 border-b">{row.working_existence}</td>
                  <td className="py-2 border-b">{row.job_change_availability}</td>
                  <td className="py-2 border-b">{row.interview_availability}</td>
                  <td className="py-2 border-b">{row.interview_desired_md}</td>
                  <td className="py-2 border-b">{row.interview_desired_time}</td>
                  <td className="py-2 border-b">{row.call_type}</td>
                  <td className="py-2 border-b">{row.call_time}</td>
                  <td className="py-2 border-b">
                    {row.voice_url ? (
                      <div>
                        <IconButton onClick={() => handleAudioSet(row.voice_url)} sx={{ borderRadius: 2, backgroundColor: "#EAEFF9", color: "primary.main" }}>
                            <IoPlay />
                        </IconButton>
                        <Modal
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          open={open}
                          onClose={handleClose}
                          closeAfterTransition
                          slots={{ backdrop: StyledBackdrop }}
                        >
                          <Fade in={open}>
                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[30%] bg-white rounded-lg border border-gray-200 shadow-lg p-6">
                              <AudioPlayer handleClose={handleClose} voiceUrl={selectVoiceUrl} setError={setError} />
                            </div>
                          </Fade>
                        </Modal>
                      </div>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
            ))}
          </tbody>
        </table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Results per page"
        // className=' fixed right-16 bottom-5'
      />
      <Error content={error} />
    </div>
  );
};

const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

export default ResultTable;
