import React, { useContext, useRef, useState } from 'react';
import { FaCloudDownloadAlt } from "react-icons/fa";
import { AiOutlineCloudUpload } from "react-icons/ai";
import axios from 'axios';
import Error from "../notification/error";
import Notification from "../notification/notification";
import Dialog from '../notification/dialog';
import { TimeContext } from '../../context/TimeContext';

const CallingCard = ({ title, buttonTextBlue, buttonTextRed, userId }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fileInputRef = useRef(null);
  const { startHour, startMinute } = useContext(TimeContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [slotStatus, setSlotStatus] =
    useState(`ここにCSVファイルをドラッグ&ドロップしてください
              または
              ここをクリックしてファイルを選択してください
            `);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [delMessage, setDelMessage] = useState("");
  const [isDelete, setIsDelete] = useState(false);

  const downloadSample = (file) => {
    const sampleFileUrl = `/${file}`;
    const fileName = file;

    const link = document.createElement('a');
    link.href = sampleFileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const resetMessage = () => {
    setError('');
    setMessage('');
  };

  const handleClickSlot = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setSlotStatus(`Selected: ${file.name}`);
    } else {
      setSelectedFile(null);
      setSlotStatus(`ここにCSVファイルをドラッグ&ドロップしてください
                    または
                    ここをクリックしてファイルを選択してください
                  `);
    }
  };

  // ドラッグ&ドロップでファイルを選択する処理
  const handleDrop = (event) => {
    event.preventDefault();
    // event.stopPropagation();

    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
      setSlotStatus(`Selected: ${file.name}`);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // デフォルトの挙動（ファイルのダウンロードなど）を防ぐ
    // event.stopPropagation();
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setSlotStatus(`ここにCSVファイルをドラッグ&ドロップしてください
                  または
                  ここをクリックしてファイルを選択してください
                `);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const resetForm = () => {
    setSelectedFile(null);
    setSlotStatus(`ここにCSVファイルをドラッグ&ドロップしてください
                  または
                  ここをクリックしてファイルを選択してください
                `);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    if(isDelete) {
      setIsDelete(false);
    }
    if(delMessage) {
      setDelMessage("");
    }
  };

  const handleRegister = async () => {
    resetMessage();

    const token = localStorage.getItem('token');
    const params = {};

    if(userId !== '1') {
      params['user_id'] = userId;
    }else {
      setError('利用者が選択されていません。')
      return;
    }
    // 現在時刻
    const now = new Date();
    const nowHour = now.getHours()*60;
    const nowMinute = now.getMinutes();
    // 比較用に整数型にする
    const choiceHour = parseInt(startHour, 10)*60;
    const choiceMinute = parseInt(startMinute, 10);
    if((nowHour+nowMinute<=choiceHour+choiceMinute) && (choiceHour+choiceMinute<=nowHour+nowMinute+10)) {
      setError('架電開始時間前の10分間は登録を行えません。');
      return;
    }
    if (selectedFile) {
      // アップロード開始
      setSlotStatus('アップロード中...')

      const formData = new FormData();
      formData.append('file', selectedFile);
      try {
        const response = await axios.post(`${apiUrl}/api/calling_list/outbound/`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: params
        });
        setMessage(response.data.status);
      } catch (error) {
        if(error.response && error.response.data && error.response.data.error) {
          setError(error.response.data.error);
        }else {
          setError('ファイルのアップロード中にエラーが発生しました。');
        }
      }
    } else {
        setError('ファイルが選択されていません。');
    }
    resetForm();
  };

  const callingListDL = async () => {
    resetMessage();

    const token = localStorage.getItem('token');
    const params = {};

    if(userId !== '1') {
      params['user_id'] = userId;
    }else {
      setError('利用者が選択されていません。')
      return;
    }
    try {
      const response = await axios.get(`${apiUrl}/api/calling_list/outbound/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',  // CSVをblobとして受け取る
        params: params
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log("Generated Blob URL: ", url);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '登録済_架電リスト.csv');  // ファイル名を指定
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setMessage('登録済みの架電リスト(CSV)をダウンロードしました。');
    } catch (error) {
      if(error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      }else {
        setError('ファイルのダウンロード中にエラーが発生しました。');
      }
    }
    resetForm();
  };

  const handleDelete = async () => {
    resetForm();

    const token = localStorage.getItem('token');
    const params = {};
    params['user_id'] = userId;

    // 現在時刻
    const now = new Date();
    const nowHour = now.getHours()*60;
    const nowMinute = now.getMinutes();
    // 比較用に整数型にする
    const choiceHour = parseInt(startHour, 10)*60;
    const choiceMinute = parseInt(startMinute, 10);
    if((nowHour+nowMinute<=choiceHour+choiceMinute) && (choiceHour+choiceMinute<=nowHour+nowMinute+10)) {
      setError('架電開始時間前の10分間は削除を行えません。');
      return;
    }

    try {
      const response = await axios.delete(`${apiUrl}/api/calling_list/delete/`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: params
        });
      setMessage(response.data.status);
    } catch (error) {
      if(error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      }else {
        setError('削除中にエラーが発生しました。');
      }
    }
  };

  const handleDialog = () => {
    setDelMessage('データを削除しますか？');
    setIsDelete(isDelete);
  }

  return (
    <div className="bg-white rounded-lg custom-shadow py-6 ">
      <div className="flex justify-between items-center mb-4 px-6">
        <h2 className="text-xl text-gray-900 font-bold">{title}</h2>
      </div>
      <div className='p-2 border-t-[1px] border-gray-300'>
        <ol className='list-decimal pl-6 font-semibold'>
          <li>
            下記テンプレートファイルどちらかをダウンロードしてください。<br />
            <button className='text-blue-600 underline' onClick={() => downloadSample('架電データ作成シート.zip')}>Excelファイル</button>　　
            <button className='text-blue-600 underline' onClick={() => downloadSample('架電リストSample.csv')}>CSVファイル</button>
          </li>
          <li>
            ダウンロードしたファイルの2行目以降に、電話番号などの架電リスト項目を（CSVファイルの場合はカンマ区切り）で入力してください。
          </li>
          <li>
            作成したCSVファイルを下の欄にアップロードしてください。<br />
            （Excelを利用する場合はCSVファイルに変換してください。）
          </li>
          <div
            className="h-42 my-4 mr-6 py-4 bg-gray-200 flex flex-col justify-center items-center cursor-pointer"
            onClick={handleClickSlot}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <AiOutlineCloudUpload className='w-12 h-12 text-gray-600' />
            <span
              className='text-center text-gray-800 font-extrabold pb-2 px-24'
              style={{ whiteSpace: 'pre-line' }}
            >{slotStatus}
            </span>
          </div>
          <li>
            「登録」ボタンをクリックしてください。
          </li>
        </ol>
        <div className='pl-1 font-semibold text-[#E83929]'>※ 登録済み架電リストを削除する場合は「削除」ボタンをクリックしてください。なお、架電実施済みのデータは削除できません。</div>
      </div>
      <div className='flex justify-between px-2'>
        <div className='pt-5'>
          <button
            className="flex items-center px-4 py-2 border-[1px] border-gray-300 rounded-md gap-2"
            onClick={callingListDL}
          >
            <FaCloudDownloadAlt />
            <span className='font-semibold'>登録済CSVダウンロード</span>
          </button>
        </div>
        <div className="flex justify-end items-center gap-5 pt-5">
          <button
            className="px-4 py-2 border-[1px] border-gray-300 rounded-md font-semibold"
            onClick={handleCancel}
          >
            キャンセル
          </button>
          <button
            className={`px-4 py-2 text-white rounded-md font-semibold ${
              userId === '1' ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#D71959]'
            }`}
            onClick={handleDialog}
            disabled={userId === '1'}
          >
            {buttonTextRed}
          </button>
          <button
            className={`px-4 py-2 text-white rounded-md font-semibold ${
              userId === '1' ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#2978CA]'
            }`}
            onClick={handleRegister}
            disabled={userId === '1'}
          >
          {buttonTextBlue}
          </button>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        accept=".csv"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      {delMessage && (
        <Dialog
          content={delMessage}
          buttonText={'削除'}
          isDelete={isDelete}
          onDelete={() => handleDelete()}  // 削除処理を直接呼び出すコールバック
          onClose={() => resetForm()}
        />
      )}
      {message && <Notification content={message} />}
      {error && <Error content={error} />}
    </div>
  );
};

export default CallingCard;
