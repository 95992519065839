import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Error from "../../component/notification/error";
import Notification from "../../component/notification/notification";
import Header from "../../component/common/header";
import { UserContext } from "../../context/UserContext";
import { Container } from "@mui/material";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

const ResetPassword = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const routerLocation = useLocation();
  const { user, logout } = useContext(UserContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [notification, setNotification] = useState("");
  const [error, setError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(routerLocation.search);
    const token = params.get("token");
    console.log('token: ', token);
    if (token) {
      let retryCount = 0;
      const maxRetries = 5; // 最大試行回数
      const interval = setInterval(() => {
        const isTokenStored = localStorage.getItem("token");
        if (!isTokenStored) {
          localStorage.setItem("token", token);
          console.log("Token stored in localStorage:", localStorage.getItem("token"));
        }

        // トークンが正しくセットされた場合、または最大試行回数を超えた場合に終了
        if (localStorage.getItem("token") || retryCount >= maxRetries) {
          clearInterval(interval);
        }
        retryCount++;
      }, 200); // 500msごとに試行
    }
  }, [routerLocation.search]);

  // 空欄の有無をチェックする
  useEffect(() => {
    if (
      email &&
      password &&
      confirmPassword
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [email, password, confirmPassword]);

  const handleLogout = () => {
    logout();
  }

  // パスワードの設定
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setValidPassword(validatePassword(newPassword));
  };
  // パスワードのバリデーションチェック
  const validatePassword = (password) => {
    const pattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    return pattern.test(password);
  };

  const handleSubmit = async (e) => {
    const token = localStorage.getItem('token');

    e.preventDefault();
    setNotification("");
    setError("");

    if(isButtonDisabled) {
      setError('入力されていない項目があります。');
      return;
    }
    if(user) {
      if(user.email !== email) {
        setError("ご登録のメールアドレスを入力してください。");
        return;
      }
    }else if(!handleEmailCheck(token)) {
      setError("ご登録のメールアドレスを入力してください。");
      return;
    }
    if(!validPassword) {
      setError('パスワードのフォーマットが間違っています。');
      return;
    }
    if(password !== confirmPassword) {
      setError("新しいパスワードと新しいパスワード(確認)が一致しません");
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/api/authentication/reset-password/`,
        {
          password: password,
          confirmPassword: confirmPassword,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          }
        }
      );

      if (response.status === 200) {
        setNotification("パスワードリセットに成功しました。");
        handleLogout();
        navigate('/login');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.error || "An unexpected error occurred.");
      } else {
        setError("An error occurred while making the request.");
      }
    }
  };

  const handleEmailCheck = async(token) => {
    console.log('token: ', token);
    try {
      const response = await axios.post(
        `${apiUrl}/api/authentication/checkEmail/`,
        {
          email
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          }
        }
      );
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("メールアドレスは存在しません:", error.response.data.message);
      } else {
        console.error("リクエスト中にエラーが発生しました:", error.message);
      }
      return false;
    }
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="h-screen overflow-hidden">
      <Header />
      <Container className="h-full flex flex-col justify-center">
        <div className="h-full mx-16 pb-24">
          <div className="h-full flex flex-col items-center justify-center flex-grow mx-24 p-24">
            <div className="w-full max-w-[650px] bg-white p-10 px-20">
              <form
                className="w-full"
                onSubmit={handleSubmit}
                noValidate
                id="reset_password_form"
              >
                <div className="mb-10">
                  <h1 className="text-2xl font-bold text-gray-900 mb-3">
                    パスワード変更
                  </h1>
                </div>
                <div className="mb-6">
                  <label htmlFor="" className="text-blue-700 text-lg font-bold">メールアドレス</label>
                  <input
                    id="email"
                    name="email"
                    type="text"
                    value={email}
                    onChange={handleEmailChange}
                    style={{
                      width: '100%',
                      padding: '12px 16px',
                      boxSizing: 'border-box',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                    }}
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="" className="text-blue-700 text-lg font-bold">新しいパスワード</label>
                  <div style={{ width: '100%', position: 'relative' }}>
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={handlePasswordChange}
                      className="w-full px-4 py-3 border-b-2 border-gray-200 focus:outline-none focus:border-gray-500"
                      autoComplete="off"
                      required
                      style={{
                        width: '100%',
                        padding: '12px 16px',
                        boxSizing: 'border-box',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                      }}
                    />
                    <button
                      type="button"
                      onClick={handleTogglePassword}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      {showPassword ? <MdOutlineVisibility /> : <MdOutlineVisibilityOff />}
                    </button>
                  </div>
                  {
                    !validPassword && password.length > 0
                      ? <div className="text-sm text-red-600 font-semibold">パスワードは8文字以上で、少なくとも小文字1文字、大文字1文字、数字1文字、記号1文字を含む必要があります。<br></br>※次の記号は使用できません。（&quot;&#39;=~|&lt;&gt;?）</div>
                      : <div className="text-sm text-gray-700">パスワードには半角英数字に記号を組み合わせた8文字以上を入力してください。※次の記号は使用できません。（&quot;&#39;=~|&lt;&gt;?）</div>
                  }
                </div>
                <div className="mb-6">
                  <label htmlFor="" className="text-blue-700 text-lg font-bold">新しいパスワード（確認）</label>
                  <div style={{ width: '100%', position: 'relative' }}>
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="w-full px-4 py-3 border-b-2 border-gray-200 focus:outline-none focus:border-gray-500"
                      autoComplete="off"
                      required
                      style={{
                        width: '100%',
                        padding: '12px 16px',
                        boxSizing: 'border-box',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                      }}
                    />
                    <button
                      type="button"
                      onClick={handleToggleConfirmPassword}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      {showConfirmPassword ? <MdOutlineVisibility /> : <MdOutlineVisibilityOff />}
                    </button>
                  </div>
                </div>
                <button
                  type="submit"
                  className="w-full justify-center py-3 bg-[#2467C1] text-white font-bold rounded-full hover:bg-blue-800 focus:outline-none focus:bg-[#0e1225]"
                >
                  <span className="inline-block mr-2">変更</span>
                </button>
              </form>
              {notification && <Notification content={notification} />}
              {error && <Error content={error} />}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ResetPassword;
